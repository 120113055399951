/** @jsx jsx */
import { jsx } from 'theme-ui'
import { ButtonLink } from '@uswitch/trustyle.button-link'

export default ({ children, href, link, ...props }) => {
  const hrefLink = href || link
  return (
    <ButtonLink href={hrefLink} {...props}>
      {children}
    </ButtonLink>
  )
}
